import styles from './links.module.css';
import {Button, Carousel, Col, Collapse, FloatButton, Modal, Row} from "antd";
import React, {LegacyRef, useEffect, useRef, useState} from "react";
import LinkCard from "../../components/links/link_card";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import {MailOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {CarouselRef} from "antd/lib/carousel";
import {useNavigate} from "react-router-dom";

const Links: React.FC = () => {

  const { t } = useTranslation()
  const navigate = useNavigate();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const normalCarouselRef: LegacyRef<CarouselRef> = useRef(null);
  const modalCarouselRef: LegacyRef<CarouselRef> = useRef(null);

  const slides = [2,3,4,5,6,7,8,9,10,11,12,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33];

  function handleModalClose() {
    setModalIsOpen(false);
  }

  function handleModalOpen() {
    setModalIsOpen(true);
  }

  function handleSlideChanged(oldIndex: number, newIndex: number) {
    setCurrentSlide(newIndex);
  }

  function handleMailClick (){
    const to = "sebastian.hordt@abpinduction.com;";
    const cc = "robin.czarnetzki@abpinduction.com;albert.miller@abpinduction.com;stefan.andorf@abpinduction.com";
    const subject = "ABPHub";
    window.location.href = `mailto:${to}?cc=${cc}&subject=${encodeURIComponent(subject)}`;
  }

  function handleLuminaClick (){
    navigate("/abp-lumina");
  }

  useEffect(() => {
    if (modalIsOpen && modalCarouselRef.current) {
      // @ts-ignore
      modalCarouselRef.current.goTo(currentSlide);
    } else if (!modalIsOpen && normalCarouselRef.current) {
      // @ts-ignore
      normalCarouselRef.current.goTo(currentSlide, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalIsOpen]);

  return (
    <div>
      <Modal
        title="ABP Induction"
        centered
        onCancel={handleModalClose}
        onOk={handleModalClose}
        open={modalIsOpen}
        width={"100%"}
        footer={[
          <Button key="close" onClick={handleModalClose}>
            {t('navigation.modal.close')}
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <Carousel
              arrows
              beforeChange={handleSlideChanged}
              dotPosition={"top"}
              draggable={true}
              ref={modalCarouselRef}
            >
              {
                slides.map((slide) =>
                  <div key={slide}>
                    <div className={styles.carouselContent}>
                      <img
                        src={"/img/carousel/Folie" + slide + ".png"}
                        alt={"Demo"}
                        style={{maxWidth: "100%"}}
                      />
                    </div>
                  </div>
                )
              }
            </Carousel>
          </Col>
        </Row>
      </Modal>
      <Row>
        <Col
          xs={{
            span: 24,
          }}
          xxl={{
            span: 12,
          }}
        >
          <div
            style={{cursor: "pointer"}}
            onClick={handleModalOpen}
          >
            <Carousel
              autoplay
              autoplaySpeed={7000}
              arrows
              beforeChange={handleSlideChanged}
              dotPosition={"top"}
              draggable={true}
              onSwipe={(index) => console.log(index)}
              ref={normalCarouselRef}
            >
              {
                slides.map((slide) =>
                  <div key={slide}>
                    <div className={styles.carouselContent}>
                      <img className={styles.bannerStyle} src={"/img/carousel/Folie" + slide + ".png"}
                           alt={"Demo"}/>
                    </div>
                  </div>
                )
              }
            </Carousel>
          </div>
        </Col>
        <Col
          xs={{
            span: 24,
          }}
          xxl={{
            span: 12,
          }}
        >
          <Collapse
            bordered={false}
            defaultActiveKey={['1']}
            style={{
              marginBottom: 25
            }}
          >
            <CollapsePanel
              header="ABP Induction Group"
              key="1"
            >
              <div style={{display: "flex", flexWrap: "wrap"}}>
                <LinkCard name="ABP Induction" link="https://abpinduction.com" logo="abp.png"
                          description="abp"
                          internal={false}/>
                <LinkCard name="Copilot" link="https://m365.cloud.microsoft/chat/?auth=2" logo="copilot.png"
                          description="copilot"
                          internal={false}/>
                <LinkCard name="CWA" link="https://abp.cwaflow.net/smartprocess" logo="cwa.png"
                          description="cwa"
                          internal={false}/>
                <LinkCard name="Eplas" link="https://abp.eplas.net/" logo="eplas.png"
                          description="eplas"
                          internal={false}/>
                <LinkCard name="E-Learning" link="https://elearning.abpinduction.com/"
                          logo="eplatform.png"
                          description="elearning" internal={false}/>
                <LinkCard name="Q.wiki" link="https://abpinduction.qwikinow.de/" logo="qwiki.png"
                          description="qwiki"
                          internal={false}/>
                <LinkCard name="Salesforce" link="https://abp-induction.my.salesforce.com/one/one.app"
                          logo="salesforce.png" description="salesforce" internal={false}/>
                <LinkCard name="Translator" link="/translator" logo="translator.png"
                          description="translator" internal={true}/>
              </div>
            </CollapsePanel>
          </Collapse>
          <Collapse
            bordered={false}
            defaultActiveKey={['1']}
          >
            <CollapsePanel
              header="ABP Induction Germany"
              key="1"
            >
              <div style={{display: "flex", flexWrap: "wrap"}}>
                <LinkCard name="Interflex" link="https://denrdoas15.pillar.com/eVAYO"
                          logo="interflex.png"
                          description="interflex" internal={false}/>
                <LinkCard name="Onesto" link="https://www.onesto.de/abp" logo="onesto.png"
                          description="onesto"
                          internal={false}/>
                <LinkCard name="Rydoo" link="https://app.rydoo.com/" logo="rydoo.png"
                          description="rydoo"
                          internal={false}/>
                <LinkCard name="User Helpdesk" link="https://abpinduction.qwikinow.de/Germany/Processes/UserHelpdesk?title=user-helpdesk" logo="help-desk.png"
                          description="helpdesk" internal={false}/>
                {/*<LinkCard name="ABP LUMINA" link="/abp-lumina" logo="abp_lumina.png"*/}
                {/*          description="abp-lumina" internal={true}/>*/}
              </div>
            </CollapsePanel>
          </Collapse>
        </Col>
      </Row>
      <FloatButton.Group
        trigger="click"
        style={{
          right: 24,
          bottom: 62,
        }}
        icon={<QuestionCircleOutlined/>}
      >
        <FloatButton
          icon={<MailOutlined/>}
          onClick={handleMailClick}
        />
        <FloatButton
          icon={<img
            className={styles.logoImage}
            alt='ABP Lumina'
            src={"/img/logo/abp_lumina.png"}
            style={{
              width: 28,
              marginLeft: -5
            }}
          />}
          onClick={handleLuminaClick}
        />
      </FloatButton.Group>
    </div>
  );
}

export default Links;