import {Layout} from "antd";
import {Content, Footer, Header} from "antd/es/layout/layout";
import {HomeOutlined} from "@ant-design/icons";
import {Outlet, useNavigate} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";

interface WrapperProps {
  header: string;
}

const Wrapper: React.FC<WrapperProps> = ({header}) => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  function navigateHome() {
    navigate("/");
  }


  return (
    <div>
      <Layout>
        <Header
          className="header"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            paddingLeft: "15px",
          }}
        >
          <div className="header-content">
            <HomeOutlined
              onClick={navigateHome}
              style={{
                fontSize: '38px',
                color: "#F58155",
                cursor: "pointer",
              }}
            />
            {header && <img className="header-image-app" src={"/img/header/" + header} alt={""}></img>}
            <h1 style={{color:'#f5835d'}}>{!header && <span>{t("title")}</span>}</h1>
          </div>
        </Header>
        <Layout style={{ padding: '0 24px 24px' }}>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              marginLeft: 10,
              paddingBottom: 80,
              marginTop: 64,
              marginBottom:64,
              minHeight: 280,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
        <Footer>&copy; {new Date().getFullYear()} ABP Induction</Footer>
      </Layout>
    </div>
  )
}

export default Wrapper;