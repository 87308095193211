import { Alert } from 'antd';
import Link from 'antd/es/typography/Link';
import React, { useEffect, useState } from 'react'
import ChatBot from 'react-chatbotify';
import { useTranslation } from 'react-i18next';

const AbpLumina: React.FC= () => {

  const {t,i18n } = useTranslation()

  const [helpOptions, setHelpOptions]= useState<string []>(["What is the role of Thermprof", "How many coils can be operated", "what is the edge temperature"]);
  const [startMessage, setStartMessage]=useState<string>(" ");
  const [endMessage, setEndMessage]=useState<string>(" ");
  const [promptMessage, setPromptMessage]=useState<string>(" ");
  const [chatPlaceHolderText, setChatPlaceHolderText] = useState<string>(" ");
  
  useEffect(()=>{
    switch(i18n.language){
        case "de":
          setChatPlaceHolderText("Wie kann ich dir helfen?")
          setHelpOptions([" Was ist die Rolle von Thermprof", "Wie viele Spulen können betrieben werden", "Was ist die Randtemperatur?"])
          setStartMessage("Hallo👋! Ich bin Lumina💡,Dein persönlicher KI-Assistent🤖. Lass uns anfangen🛫")
          setEndMessage("Danke, dass du unseren Bot verwendest😃!")
          setPromptMessage("Gib mir drei mögliche tiefere Fragen, die basierend auf meiner letzten Anfrage wichtig sein könnten, und trenne sie mit einem Semikolon. Bitte stelle sicher, dass die Fragen nicht zu häufig wiederholt werden.")
        break;
        case "en":
          setChatPlaceHolderText("How could i help you?")
          setStartMessage("Hello👋! I am Lumina💡, your personal AI assistant🤖. Let's get started🛫")
          setEndMessage("Thanks fo using our Bot😃!")
          setPromptMessage("Give me three possible deeper questions that might be important based on my last request, and separate them with a semicolon. Please ensure that the questions do not repeat too frequently.")
         
        break;
        default:
          setChatPlaceHolderText("How could i help you?")
          setStartMessage("Hello👋! I am Lumina💡, your personal AI assistant🤖. Let's get started🛫")
          setEndMessage("Thanks fo using our Bot😃!")
          setPromptMessage("Give me three possible deeper questions that might be important based on my last request, and separate them with a semicolon. Please ensure that the questions do not repeat too frequently.")
    }

    // eslint-disable-next-line
  },[])

  async function postData(url: string, data: any): Promise<any> {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

       if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
       }

    const responseData = await response.json();
    return responseData;
    } catch (error) {
    console.error('Error posting data:', error);
    throw error;
    }
  }
  const convertToStringArray = (input: string): string[] => {
                return input.split(';');
  };
     
  const call = async (params:any) => {
    if(params.userInput ===""){
        console.log("")
    }
    else{
      try {
        const response = await postData(process.env.REACT_APP_LUMINA_API_HOST + '/message' ,{"message": params.userInput } )
          await params.injectMessage(response);
      } catch (error) {
          await params.injectMessage("Invalid Input");
      }
    }
  }
  const gptCall= async() =>{
    const gptResponse=await postData(process.env.REACT_APP_LUMINA_API_HOST + '/message',{"message":promptMessage} )
    setHelpOptions(convertToStringArray(gptResponse));
  }
  const flow = {
  start: {
    message: startMessage,
    transition: {duration: 1000},
            path: "loop"
  },
  loop: {
    message: async (params: any) => {
      await call(params);
      gptCall();
     
    },
    options: helpOptions,
    path: (params: any) => {
      if (params.userInput !== "end") {
        return "loop";
      }
      return "end";
    }
  },
  end: {
    message: () => endMessage,
    chatDisabled: true
  },
  }
  const styles ={
      chatWindowStyle: {
        backgroundColor: '#f2f2f2',
        width: '93.5vw',
        height: '90vh', 
      },
      chatInputAreaFocusedStyle: {
		    backgroundColor: "white",
		    color: "black",
        border:"1px solidrgb(177, 171, 166)",
        boxShadow:"0px 0px 10px 0px rgba(0,0,0,0.5)"
	    },
  
      headerStyle: {
        backgroundColor:'#F58155',
        backgroundImage: 'linear-gradient(to right, #F58155 , #F58155);'
      },
      botBubbleStyle: {
		   backgroundColor: "#E5E7EB",
       color:"black",
		   border: "1px solid #F58155",
		   borderRadius: "8px",
		   boxShadow: "rgba(221, 221, 221, 0.2) 0px 0px 9px 0px"
	    },
      botOptionStyle:{
        border:"1px solid #2E2D2C",
        color:"#2E2D2C",
      },
      botOptionHoveredStyle: {
		   backgroundColor: "#F58155",
		   marginTop: 1,
		   marginLeft: 1,
		   boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1))"
      },
      userBubbleStyle:{
        backgroundColor:" #F58155",
        color:"white"
      },
      sendButtonStyle: {
		   backgroundColor: "#2E2D2C"
	    },
      sendButtonHoveredStyle: {
        backgroundColor: "#F58155"
      },
  }
  const settings = {
      general: { embedded: true,showFooter: false },
      chatHistory: { storageKey: "example_faq_bot" },
      chatInput: {
        enabledPlaceholderText: chatPlaceHolderText,
        allowNewline: true
      },
      header:{
        title:" ",
        buttons:[]
      },
      notification:{
        disabled:true
      }
      } 
  
    return (
      <div>
        <Alert
          message={t("bot.warning")}
          description={
            <span>
              <b>{t("bot.bold.first")}</b> {t("bot.normal.first")} <b>{t("bot.bold.second")}</b> {t("bot.normal.second")} <Link href="mailto:myabp.de@abpinduction.com?&subject=Feedback">myabp.de@abpinduction.com</Link> {t("bot.normal.third")}
            </span>}
          type="warning"
          showIcon
          
        />
        <ChatBot settings={settings} flow={flow} styles={styles}/>
      </div>
    );
};

export default AbpLumina
