import {useMutation, useQuery} from "@tanstack/react-query";
import axios from "axios";

export function SendGetRequest(queryKey:string, urlPath: string, enabled = true) {
  return useQuery({
    queryKey: [queryKey],
    enabled: enabled,
    queryFn: () =>
      fetch(urlPath).then((res) => {
        if (!res.ok) {
          throw new Error(`Fehler: Unerwarteter Statuscode ${res.status}`);
        }
        return res.json();
      }),
  })
}

export function SendPostRequest(urlPath: string ) {
  return useMutation({
    mutationFn: (payload: any) => {
      return axios.post(urlPath, payload)
    },
  })
}

export function SendPutRequest(urlPath: string) {
  return useMutation({
    mutationFn: (payload: any) => {
      return axios.put(urlPath, payload,)
    },
  })
}

export function SendDeleteRequest(urlPath: string) {
  return useMutation({
    mutationFn: () => {
      return axios.delete(urlPath)
    },
  })
}

export function GetWebsocketConnection(urlPath: string) {
  return new WebSocket(urlPath)
}

