import './i18n/config';
import React from 'react';
import './App.css';
import {ConfigProvider} from "antd";
import {Outlet} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";


const queryClient = new QueryClient()

const App: React.FC = ()=> {

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: '#F5835D',
          },
        }}
      >
        <Outlet />
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
