import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from 'i18next-http-backend';

i18next
  .use(I18nextBrowserLanguageDetector)
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    detection: { order: ["navigator"] },
    fallbackLng: 'en',
});
