import styles from './link_card.module.css';
import React from "react";
import {Card, Tooltip} from 'antd';
import { useTranslation } from 'react-i18next';
import {useNavigate} from "react-router-dom";

const { Meta } = Card;

interface LinkCardProps {
  name: string;
  link: string;
  logo: string;
  description: string;
  internal: boolean;
}

const LinkCard: React.FC<LinkCardProps> = ({name, link, logo, description, internal}) => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const openApp = () => {
    if (internal) {
      navigate(link);
    } else {
      window.open(link, "_blank");
    }
  }

  return (
    <div onClick={() => openApp()}>
      <Tooltip
        placement="bottom"
        title={t('links.tooltip.' + description)}
        color={"#F58155"}
      >
        <Card
          hoverable
          className={styles.greyscale}
          style={{
            width: 200,
            height: 210,
            padding: 5,
            margin: 5,
          }}
          cover={
            <div
              className={styles.logoContainer}
              style={{
                textAlign: "center",
                minHeight: "155px"
              }}
            >
              <img
                className={styles.logoImage}
                alt={name}
                src={"/img/logo/" + logo}
              />
            </div>
          }
        >
          <Meta title={name}/>
        </Card>
      </Tooltip>
    </div>
  );
}

export default LinkCard;