import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {Alert, Button, Card, Col, Row, Select, Spin, Tooltip} from "antd";
import {languageOptions} from "../../components/translator/languageList";
import TextArea from "antd/es/input/TextArea";
import {CopyFilled, LoadingOutlined} from "@ant-design/icons";
import {SendPostRequest} from "../../api/api";

const Translator: React.FC = () => {

  const { t } = useTranslation();

  const [textInput, setTextInput] = useState("")
  const [textOutput, setTextOutput] = useState("")
  const [inputLan, setInputLang] = useState("")
  const [outputLan, setOutputLang] = useState("English");
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Small workaround to avoid duplicated API calls when the source language is set automatically
  const [inputLanSetManually, setInputLanSetManually] = useState(false);

  // const createTranslateRequest = SendPostRequest(window.origin + '/api/translation/translate');
  const createTranslateRequest = SendPostRequest('https://hub.abpinduction.com/api/translation/translate');

  async function callTranslateApi() {
    createTranslateRequest.mutate({
      "text": textInput,
      "input_language": inputLan,
      "output_language": outputLan
    });
  }

  function onInputChanged(input: any) {
    setTextInput(input.target.value);
  }

  function onInputLangChanged(value: string) {
    setInputLang(value);
  }

  function onOutputLangChanged(value: string) {
    setOutputLang(value);
  }

  function onCopyClick() {
    navigator.clipboard.writeText(textOutput);
  }

  useEffect(() => {

    if (createTranslateRequest.data ) {
      setTextOutput(createTranslateRequest.data.data.translation);
      setIsLoading(false);

      // Setze die Ausgangssprache, die erkannt wurde, falls nicht bisher eine gesetzt wurde
      if (inputLan === "") {
        setInputLang(createTranslateRequest.data.data.language);
        setInputLanSetManually(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createTranslateRequest.data]);

  useEffect(() => {

    if (createTranslateRequest.error) {
      console.log('createTranslateRequest error:', createTranslateRequest.error.message);
      setShowAlert(true);
    }
  }, [createTranslateRequest.error]);

  useEffect(() => {
    if(textInput !== "" && outputLan !== "") {
      setIsLoading(true);
      let timeOutForApiCall = setTimeout(callTranslateApi, 500);

      return () => clearTimeout(timeOutForApiCall);
    } else if(textInput === "") {
      setTextOutput("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textInput]);

  useEffect(() => {
    if(textInput !== "" && outputLan !== "" && !inputLanSetManually) {
      setIsLoading(true);
      callTranslateApi();
    } else {
      setInputLanSetManually(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputLan, outputLan]);


  return (
    <div>
      <Alert
        message="Translation service not available"
        type="error"
        showIcon
        style={showAlert ? {display: 'flex'} : {display: 'none'}}
      />
      <Row>
        <Col
          span={12}
          style={{paddingRight: "5px"}}
        >
          <Card
            title={
              <Select
                allowClear={true}
                onChange={onInputLangChanged}
                options={languageOptions.map((lang: string) => ({ label: lang, value: lang }))}
                placeholder={t("translator.input.select")}
                showSearch={true}
                style={{
                  width: 350,
                }}
                value={inputLan !== "" ? inputLan : null}
              />
            }
            style={{height:"450px"}}
          >
            <TextArea
              onChange={onInputChanged}
              placeholder={t("translator.input.placeholder")}
              rows={15}
            />
          </Card>
        </Col>
        <Col
          span={12}
          style={{paddingLeft: "5px"}}
        >
          <Card
            title={
              <>
                <Select
                  defaultValue="English"
                  onChange={onOutputLangChanged}
                  options={languageOptions.map(lang => ({label: lang, value: lang}))}
                  showSearch={true}
                  style={{
                    width: 350,
                    marginRight: "10px"
                  }}
                />
                <Tooltip title={t("translator.output.copy")}>
                  <Button
                    onClick={onCopyClick}
                    shape="circle"
                  >
                    <CopyFilled
                      style={{color: "#F58155"}}
                    />
                  </Button>
                </Tooltip>
              </>
            }
            style={{height: "450px"}}
          >
            {isLoading
              ? <div
                style={{textAlign: "center"}}
              >
                <Spin indicator={<LoadingOutlined spin/>} size="large"/>
              </div>
              : <p
                style={{
                  height: "300px",
                  overflowY: "scroll",
                  whiteSpace: "pre-line"
                }}
              >
                {textOutput !== "" ? textOutput : t("translator.output.placeholder")}
              </p>
            }
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Translator;