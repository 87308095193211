import React, {FC} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import NotFound from "./pages/not_found";
import {AuthProvider, withAuthenticationRequired} from "react-oidc-context";
import {User} from "oidc-client-ts";
import Links from "./pages/hub/links";
import Translator from "./pages/translator/translator";
import Wrapper from "./pages/wrapper";
import AbpLumina from './pages/abp_lumina/abp_lumina';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Removed the get attributes from the login call
const onSigninCallback = (_user: User | void): void => {
  window.history.replaceState(
    {},
    document.title,
    window.location.pathname
  )
}

const oidcConfig = {
  authority:  process.env.REACT_APP_KC_URL,
  client_id: process.env.REACT_APP_KC_CLIENT_ID,
  redirect_uri: window.location.href,
  onSigninCallback: onSigninCallback,
};

const withProtectedRoute = (Component: FC<Object>) => withAuthenticationRequired(Component, {
  OnRedirecting: () => (<div>Redirecting to the login page...</div>),
  // ensures that the user will be redirected to the previous page instead the home page
  signinRedirectArgs: {
    redirect_uri: window.location.href
  }
});

// Komponente, die die Admin-Komponente als Prop erhält
const ProtectedRoute = ({ Component }: {Component: FC<Object>} ) => {
  const ProtectedComponent = withProtectedRoute(Component);
  return <ProtectedComponent />;
};

root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ProtectedRoute Component={App} />}>
            <Route element={<Wrapper header={'abp_hub.png'} />}>
              {/* Default Page */}
              <Route index element={<Links/>} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="translator" element={<Wrapper header={'abp_translator.png'} />}>
              <Route index element={<Translator/>} />
            </Route>
            <Route path="abp-lumina" element={<Wrapper header={'abp_lumina2.png'} />}>
              <Route index element={<AbpLumina/>} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);
